
export const jobList = [
    {
        id: 0,
        roles: "Software Engineering",
        list: [ 
            "Fullstack Developer", 
            "Backend Developer", 
            "Frontend Developer",
            "DevOps / DevSecOps Engineer", 
            "QA / Test Engineer",
            "Security /Network Engineer",
            "IT Infrastructure"
        ]
    },
    {
        id: 1,
        roles: "Product Management",
        list: [ 
            "Product Owner",
            "Product Design",
            "Product Manager",
            "IT Project Manager",
            "Delivery Manager"
        ]
    },
    {
        id: 2,
        roles: "Marketing & Communications",
        list: [      
            "Account Manager",
            "Digital Business Representative",
            "Social Media Specialist",
            "Account Executive",
            "Brand / Product Management",
            "Partnership Manager",
            "Graphic Designer",
            "Technical Writer"
        ]
    },
    {
        id: 3,
        roles: "Data Science",
        list: [      
            "Database Administrator",
            "Data Engineer",
            "Data Quality",
            "Data Scientist",
            "Data Steawards",
            "Data Analyst"
        ]
    },
    {
        id: 4,
        roles: "Services",
        list: [      
            "Waiters",
            "Supervisor",
            "Crew Floor",
            "Crew Kitchen",
            "Host Live "
        ]
    }
];


export const positionList = [
    {
        id: 0,
        position: "Devsecops",
        experienceLevel: 0,
        experienceLevelName: "Min 0 Years",
        jobType: 0,
        jobTypeName: "On Site",
        educationLevel: 0,
        educationLevelName: "Senior High School/Vocational High School",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [
            "At least 2 years experience in the relevant field",
            "Experience with cloud security or other security applications",
            "Experienced in penetration testing to identify security vulnerabilities within applications",
            "Ability to perform security risk analysi",
            "Capable of collaborating with teams effectively",
            "Effective communication skill with ability to influence "
        ]
    },
    {
        id: 1,
        position: "Digital Sales Manager",
        experienceLevel: 4,
        experienceLevelName: "Min 4 Years",
        jobType: 0,
        jobTypeName: "Full Time",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Digital Marketing",
        requirements: [ 
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or related fields",
            "Prior experience in sales role is crucial (Minimum 3 years experienced). Candidates should have a deep understanding of the digital marketing landscape, including various channels such as social media, PPC, SEO, email marketing, etc.",
            "Minimum of 5 years of experience in digital agency and 5 years’ experience in managerial position.",
            "Proven track record of successfully closing sales deals and achieving revenue targets.",
            "Strong communication skills and negotiation skills are essential for with clients, delivering compelling sales pitches, and articulating complex digital marketing concepts in a clear and concise manner.",
            "Ability to work independently and as part of a team in a fast-paced and target-driven environment.",
            "Ability to think strategically and develop tailored solutions to meet clients' needs and objectives.",
            "Commitment to providing exceptional customer service and maintaining long-term relationships with clients.",
            "Willingness to stay updated on industry developments, emerging technologies, and evolving consumer behaviors to stay ahead of the curve and drive innovation in digital marketing strategies.",
            "Fluent in English."
        ]
    },
    {
        id: 2,
        position: "Digital Business Representative",
        experienceLevel: 3,
        experienceLevelName: "Min 3 Years",
        jobType: 0,
        jobTypeName: "Full Time",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Digital Marketing",
        requirements: [      
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or related fields", 
            "Prior experience in sales role or digital marketing is crucial (Minimum 3 years experienced). Candidates should have a deep understanding of the digital marketing landscape, including various channels such as social media, PPC, SEO, email marketing, etc.",
            "Proven track record of successfully closing sales deals and achieving revenue targets.",
            "Strong communication skills and negotiation skills are essential for with clients, delivering compelling sales pitches, and articulating complex digital marketing concepts in a clear and concise manner.",
            "Ability to work independently and as part of a team in a fast-paced and target-driven environment.",
            "Ability to think strategically and develop tailored solutions to meet clients' needs and objectives.",
            "Commitment to providing exceptional customer service and maintaining long-term relationships with clients.",
            "Willingness to stay updated on industry developments, emerging technologies, and evolving consumer behaviors to stay ahead of the curve and drive innovation in digital marketing strategies.",
            "Fluent in English",
        ]
    },
    {
        id: 3,
        position: "Senior Account Manager",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years",
        jobType: 0,
        jobTypeName: "Full Time",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Digital Marketing",
        requirements: [      
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or related field.", 
            "Minimum of 8 years of experience in digital marketing and 2 years experience in managerial position",
            "Proven track record of success in managing client accounts and delivering measurable results across various digital channels such as social media, PPC, SEO, email marketing, DSPs (DV360, Google Ads, TTD, etc.), Facebook Ads or TikTok ads",
            "Strong analytical skills and proficiency in leveraging data to drive decision-making and optimize campaign performance.",
            "Proven ability to identify upselling opportunities within existing client accounts and effectively communicate the value proposition of additional services or solutions.",
            "Demonstrate leadership qualities, project management skills, and the ability to mentor and coach junior team members as needed.",
            "Willingness to stay updated on industry trends, emerging technologies, and best practices in digital marketing to remain at the forefront of the industry and drive innovation in client strategies.",
            "Provide previous portfolio from previous work of performance ads is preferred.",
            "Fluent in English"
        ]
    },
];


