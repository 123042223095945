import { useState, useEffect } from "react";
import background from "../../../asset/svg/radiant-gradient.svg";
import { positionList } from "../../../components/particles/DataLists";
import { Link } from "react-router-dom";

const Portal = () => {
    const [open, setOpen] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState(positionList);
    const [experienceLevel, setExperienceLevel] = useState(0);
    const [educationalLevel, setEducationalLevel] = useState(0);
    const [jobType, setJobType] = useState(0);
    const [type, setType] = useState(0);

    const handleInputChange = (event) => {
      const { value } = event.target;
      setSearchTerm(value);
    };

    const onClickSearch = () => {
        filterData(searchTerm);
    };
  
    const filterData = (searchTerm) => {
      const filteredData = positionList.filter((item) =>
        item.position.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredData);
    };

    const experienceFilter = (data) => {
        const filter = positionList.filter((item) =>
            item.experienceLevel >= parseInt(data)
        );
        setFilteredData(filter);
        setExperienceLevel(data);

    };

    const educationFilter = (data) => {
        const filter = positionList.filter((item) =>
            item.educationLevel >= parseInt(data)
        );
        setFilteredData(filter);
        setEducationalLevel(data);
    };

    const jobTypeFilter = (data) => {
        const filter = positionList.filter((item) =>
            item.jobType === parseInt(data)
        );
        setFilteredData(filter);
        setJobType(data);
    };

    const typeFilter = (data) => {
        const filter = positionList.filter((item) =>
            item.type === parseInt(data)
        );
        setFilteredData(filter);
    };

    const resetFilter = () => {
        setExperienceLevel(0);
        setJobType(0);
        setType(0);
        setFilteredData(positionList);
    };

    useEffect(() => {
        typeFilter(type);
    }, [type]);
    

    return (  
        <section className="relative py-[4.2rem]">
            <div className="w-full">
                <div>
                    <div className="flex flex-col p-2 m-h-screen py-24 bg-no-repeat bg-cover" style={{backgroundImage: `url(${background})`}}>
                        <div className="bg-white items-center justify-between w-full flex rounded-full shadow-lg p-2 mb-5 sticky max-w-7xl px-4 md:px-8 mx-auto">
                            <div>
                                <div className="p-2 mr-1 rounded-full hover:bg-gray-100 cursor-pointer">
                                    <svg className="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                            <input 
                                className="font-bold uppercase rounded-full w-full py-4 pl-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline lg:text-sm text-xs" 
                                type="text" 
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleInputChange}
                                onKeyDown={event => {if (event.key === "Enter"){{onClickSearch();}}}}
                            />
                            <button onClick={onClickSearch} className="bg-gray-600 p-2 hover:bg-blue-400 cursor-pointer mx-2 rounded-full">
                                <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </div>     
                    </div>
                </div>
                <div className="grid grid-cols-12 max-w-7xl px-4 mx-auto">
                    <div className="col-span-12 md:col-span-3 w-full max-md:max-w-md max-md:mx-auto py-6">
                        <div className="box rounded-xl border border-gray-300 bg-white p-6 w-full md:max-w-sm">
                            <div className="flex items-center justify-between w-full pb-3 border-b border-gray-200 mb-7">
                                <p className="font-medium text-base leading-7 text-black ">Filter</p>
                                <p onClick={() => resetFilter()} className="font-medium text-xs text-gray-500 cursor-pointer transition-all duration-500 hover:text-indigo-600">RESET</p>
                            </div>

                            <div>
                                <p className="font-medium text-sm leading-6 text-black mb-3">Type</p>
                                <div className="relative w-full mb-7">
                                    <select
                                        selected={jobType}
                                        onChange={(e) => jobTypeFilter(e.target.value)}
                                        className="h-12 border border-gray-300 text-gray-900 text-xs font-medium rounded-full block w-full py-2.5 px-4 appearance-none relative focus:outline-none bg-white">
                                        <option value={0}>Full Time</option>
                                        <option value={1}>Part Time</option>
                                        <option value={2}>Internship</option>
                                        <option value={3}>Project Base</option>
                                    </select>
                                    <svg className="absolute top-1/2 -translate-y-1/2 right-4 z-50" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 5.99845L8.00008 9.99862L3.99756 5.99609" stroke="#111827"
                                            strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>   

                            <div>
                                <p className="font-medium text-sm leading-6 text-black mb-3">Educational Level</p>
                                <div className="relative w-full mb-7">
                                    <select
                                        selected={educationalLevel}
                                        onChange={(e) => educationFilter(e.target.value)}
                                        className="h-12 border border-gray-300 text-gray-900 text-xs font-medium rounded-full block w-full py-2.5 px-4 appearance-none relative focus:outline-none bg-white">
                                        <option value={0}>Senior High School / Vocational High School</option>
                                        <option value={1}>Diploma's Degree</option>
                                        <option value={2}>Bachelor's Degree</option>
                                        <option value={3}>Master's Degree</option>
                                        <option value={4}>Doctor's Degree</option>
                                    </select>
                                    <svg className="absolute top-1/2 -translate-y-1/2 right-4 z-50" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 5.99845L8.00008 9.99862L3.99756 5.99609" stroke="#111827"
                                            strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>    

                            <div className="mb-10">
                                <p className="font-medium text-sm leading-6 text-black mb-3">Experience (years)</p>
                                
                                <div className="w-full">
                                    <input 
                                        type="range" 
                                        min={0} 
                                        max={5} 
                                        value={experienceLevel} 
                                        step={1} 
                                        onChange={(e) => experienceFilter(e.target.value)}
                                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"/>
                                </div>
                                <ul className="flex justify-between w-full px-[10px]">
                                    <li className="flex text-sm justify-center relative"><span className="absolute">0</span></li>
                                    <li className="flex text-sm justify-center relative"><span className="absolute">1</span></li>
                                    <li className="flex text-sm justify-center relative"><span className="absolute">2</span></li>
                                    <li className="flex text-sm justify-center relative"><span className="absolute">3</span></li>
                                    <li className="flex text-sm justify-center relative"><span className="absolute">4</span></li>
                                    <li className="flex text-sm justify-center relative"><span className="absolute">5+</span></li>
                                </ul>
                            </div>         

                            <div className="mb-3">
                                <p className="font-medium text-sm leading-6 text-black mb-3">Work Arrangement Options</p>
                                <div className="box flex flex-col gap-2">
                                    <div className="flex items-center">
                                        <input 
                                            type="checkbox" 
                                            checked={type === 0}
                                            onChange={() => setType(0)}
                                            className="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]"/>
                                        <label className="text-xs font-normal text-gray-600 leading-4 cursor-pointer">On-Site</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input 
                                            type="checkbox" 
                                            checked={type === 1}
                                            onChange={() => setType(1)}
                                            className="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]"/>
                                        <label className="text-xs font-normal text-gray-600 leading-4 cursor-pointer">Hybrid</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input 
                                            type="checkbox" 
                                            checked={type === 2}
                                            onChange={() => setType(2)}
                                            className="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]"/>
                                        <label className="text-xs font-normal text-gray-600 leading-4 cursor-pointer">Remote</label>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-9 xl:px-6 py-6">
                        {
                           filteredData.map((data, index) => {
                            return (
                                <div key={index} className="relative flex flex-col overflow-hidden p-2 border border-gray-300 rounded-xl px-5 mb-4">
                                    <div className="w-full max-w-4xl flex flex-col sm:flex-row gap-3 sm:items-center justify-between">
                                        <div>
                                            <span className="text-secondary text-sm">{data.roles}</span>
                                            <h3 className="font-bold mt-px">{data.position}</h3>
                                            <div className="flex items-center gap-3 mt-2">
                                                <button 
                                                    className="bg-secondary text-white rounded-full px-3 py-1 text-sm"
                                                    onClick={() => setOpen(data.id)}
                                                >
                                                    Details
                                                </button>
                                                <span className="text-slate-600 text-sm flex gap-1 items-center"> 
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg> 
                                                    On-site
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <Link to={`/portal/${data.id}`} state={{ state: data }}>
                                                <button className="bg-secondary text-white font-medium px-4 py-2 rounded-md flex gap-1 items-center">Detail</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className={`pt-[30px] list-disc list-inside ${open === data.id ? "" : "hidden"} transition-opacity ease-in-out delay-150 duration-300 pt-4 text-sm font-['Poppins'] space-y-2`}>
                                            {data.requirements.map((item, key) => (
                                                <li key={key}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            );
                           })
                        }
                    </div>   
                </div>
            </div>
        </section>
    );
};

export default Portal;